import styles from '../search-input-suggestions.scss';
import React from 'react';
import { ForumapiCategory } from '@wix/ambassador-communities/types';
import Renderer from '../../simple-renderer';
import Link from '../../link/internal-link';
import { SearchResultPost } from '../../../types';
import { HIGHLIGHT_STYLE } from '../constants/highlight-style';
import { SEARCH_DROPDOWN_BI_LOCATION } from '../constants/bi-location';

type SearchResultPostProps = {
  post: SearchResultPost;
  query: string;
  category: ForumapiCategory;
  onClick: () => void;
};

const SearchResultPostItem: React.FC<SearchResultPostProps> = ({
  post,
  query,
  category,
  onClick,
}) => {
  const postLink = `/${category.slug}/${post.slug}`;
  return (
    <li data-hook="search-suggestions-dropdown-result-item">
      <Link
        className={styles.dropdownItem}
        to={postLink}
        onClick={onClick}
        biLocation={SEARCH_DROPDOWN_BI_LOCATION}
      >
        <h3 className={styles.dropdownItemTitle}>
          <Renderer
            contentState={post.title!}
            isContentConverted={true}
            query={query}
            highlightStyle={HIGHLIGHT_STYLE}
            clamp={1}
            focusQueryAfterChars={15}
          />
        </h3>
        <p>
          <Renderer
            contentState={post.content!}
            isContentConverted={false}
            query={query}
            highlightStyle={HIGHLIGHT_STYLE}
            clamp={2}
            focusQueryAfterChars={35}
          />
        </p>
      </Link>
    </li>
  );
};

export default SearchResultPostItem;
