import PropTypes from 'prop-types';
import React, { FC } from 'react';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import HeaderTabs from '../header-navigation/header-navigation.component';
import { connect } from '../../../common/components/runtime-context';
import { flowRight } from 'lodash';
import { RootState } from '../../reducers/root-state';
import styles from './internal-page-tabs-header.scss';
import SearchInputSuggestions from '../../components/search-input-suggestions';
import { encodeQuery } from '../../services/query-encoding';
import { ForumapiCategory } from '@wix/ambassador-communities/types';
import { getCategory } from '../../../common/selectors/categories-selectors';

interface InternalPageTabsDesktopHeaderProps {
  params: any;
  routes: any;
  customCtaLabel: string;
  onSearch: (query: string) => void;
  onSearchFocus: () => void;
  onSearchClear: () => void;
  onSearchSuggestionsLoaded: (query: string) => void;
  searchQuery: (query: string) => void;
  fetchSearchSuggestions: (query: string, limit: number) => Promise<any>;
  getCategoryById: (categoryId: string) => ForumapiCategory;
}

const InternalPageTabsDesktopHeader: FC<InternalPageTabsDesktopHeaderProps> = ({
  params,
  routes,
  onSearch,
  onSearchFocus,
  onSearchClear,
  onSearchSuggestionsLoaded,
  customCtaLabel,
  fetchSearchSuggestions,
  getCategoryById,
  ...props
}) => (
  <>
    <DesktopHeader
      className={styles.header}
      removeVerticalSpacing={true}
      removeStartSpacing={true}
      left={<HeaderTabs />}
      right={
        <BreadcrumbsWidget
          searchInput={
            <SearchInputSuggestions
              onSearch={onSearch}
              initialQuery={params.query || ''}
              onFocus={onSearchFocus}
              onClear={onSearchClear}
              onSuggestionsLoaded={onSearchSuggestionsLoaded}
              fetchSearchSuggestions={(query: string, limit: number) =>
                fetchSearchSuggestions(query, limit)
              }
              getCategoryById={getCategoryById}
            />
          }
          params={params}
        />
      }
      {...props}
    />
  </>
);

InternalPageTabsDesktopHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state: RootState, _: any, actions: any) => ({
  onSearch: (query: string) =>
    actions.navigateWithinForum(`/search${query ? `/${encodeQuery(query)}` : ''}`),
  onSearchFocus: actions.userEventsSearchFocused,
  onSearchClear: actions.userEventsSearchCleared,
  onSearchSuggestionsLoaded: actions.userEventsSearchSuggestionsLoaded,
  fetchSearchSuggestions: actions.fetchSearchSuggestionsPromisified,
  getCategoryById: (categoryId: string) => getCategory(state, categoryId),
});

export default flowRight(connect(mapRuntimeToProps))(InternalPageTabsDesktopHeader);
